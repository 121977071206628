import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from '../services'

const useSelectGoalCustomize = () => {
  const [loading, setIsLoading] = useState(false)
  const { changeLeadData } = useLead()

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-objetivo-outros' })
  }, [])

  const onSubmit = async (payload) => {
    setIsLoading(true)

    const { goal } = payload
    const requestBody = { goal }

    analytics.track('buttonClick', {
      currentPage: 'formulario-objetivo-outros',
      detail: 'click-step-objetivo-outros',
      customLayer: {
        description: goal
      }
    })

    return services
      .putGoal({ requestBody, isCustomized: true })
      .then(({ next_step }) => {
        changeLeadData(requestBody)
        goTo(next_step)
      })
      .finally(() => setIsLoading(false))
  }

  const handlePreviousStep = () => {
    changeLeadData({
      previousStep: 'select-goal'
    })
  }

  useEffect(() => {
    handlePreviousStep()
  }, [])

  return { onSubmit, loading }
}

export default useSelectGoalCustomize
