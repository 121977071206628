import { useForm } from 'react-hook-form'

import { Navigation, Input } from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import useSelectGoalCustomize from './hooks'

const Customize = ({ pageContext }) => {
  const initialValues = {
    goal: ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, loading } = useSelectGoalCustomize()

  return (
    <ProposalLayout pageContext={pageContext}>
      <h1 className="proposal-title">Qual é o seu objetivo?</h1>
      <p className="proposal-subtitle">
        Digite em poucas palavras qual a finalidade do empréstimo
      </p>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Input.Text
          label="objetivo"
          id="goal"
          name="goal"
          maxLength="50"
          control={control}
        />
        <Navigation
          submit
          nextStep
          isNextButtonDisabled={!isValid}
          isNextStepButtonLoading={loading}
          nextButtonId="select-goal-customize-next-btn"
        />
      </form>
    </ProposalLayout>
  )
}

export default Customize
